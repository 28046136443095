<template>
  <v-container id="map-container" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <material-card color="primary" full-header class="card-group">
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold">
                <v-icon large class="mr-3">
                  mdi-map
                </v-icon>
                Simple Map
              </div>
            </div>
          </template>
          <v-col
            id="arcMap"
            cols="12"
            class="pa-5 pt-0"
            style="width:100%;height:700px"
          />
        </material-card>
      </v-col>
    </v-row>
    <!-- 由於 Indoor Map 寫在 index.html，每個 Component 都要建一個 id="myMap" 的元素，否則 console 會有錯誤訊息，更嚴謹的處理方案待研究。 -->
    <div id="myMap" />
  </v-container>
</template>

<script>
import { loadModules } from 'esri-loader'

export default {
  name: 'MapAcrGIS',
  data () {
    return {}
  },
  mounted () {
    this._createMapView()
  },
  methods: {
    _createMapView: function () {
      const _self = this // 定义一个_self防止后续操作中this丢失
      const option = {
        // 定义一个包含有JS API中js开发包和css样式文件的对象
        // url: 'http://localhost/4.14/init.js',
        // css: 'http://localhost/4.14/esri/themes/light/main.css',
        url: 'https://js.arcgis.com/4.14/init.js',
        css: 'https://js.arcgis.com/4.14/esri/themes/light/main.css'
      }

      // 通过loadModules来做衔接
      loadModules(
        [
          'esri/Map',
          'esri/views/MapView',
          'esri/widgets/BasemapToggle',
          'esri/widgets/BasemapGallery'
        ],
        option
      )
        .then(([Map, MapView, BasemapToggle, BasemapGallery]) => {
          // 业务代码在此处编写
          const map = new Map({
            // 实例化地图
            basemap: 'streets'
          })

          const view = new MapView({
            // 实例化地图视图
            container: 'arcMap',
            map: map,
            zoom: 11,
            center: [121.56994, 25.078341]
          })

          // view.ui.components = [] // 清除掉地图左上角默认的缩放图标

          const basemapToggle = new BasemapToggle({
            view: view,
            nextBasemap: 'arcgis-imagery'
          })

          view.ui.add(basemapToggle, 'bottom-right')

          const basemapGallery = new BasemapGallery({
            view: view,
            source: {
              query: {
                title: '"World Basemaps for Developers" AND owner:esri'
              }
            }
          })

          view.ui.add(basemapGallery, 'top-right') // Add to the view
        })
        .catch(err => {
          _self.$message('地图创建失败，' + err)
        })
    }
  }
}
</script>

<style lang="scss">
#myMap {
  display: none;
}
.esri-view .esri-basemap-gallery {
  min-width: 150px !important;
}
.esri-view-width-greater-than-large
  .esri-ui-corner
  .esri-basemap-gallery.esri-component
  .esri-basemap-gallery__item-container {
  flex-flow: column !important;
}
.esri-view-width-greater-than-large
  .esri-ui-corner
  .esri-basemap-gallery.esri-component {
  width: 50px !important;
  max-width: 50px !important;
}
.v-application ul,
.v-application ol {
  padding-left: 0px !important;
}
.esri-view-width-greater-than-large
  .esri-ui-corner
  .esri-basemap-gallery.esri-component {
  width: 50px !important;
  max-width: 50px !important;
}
.esri-view-width-greater-than-large
  .esri-ui-corner
  .esri-basemap-gallery.esri-component
  .esri-basemap-gallery__item {
  width: 100% !important;
}
</style>
